import React, { Component } from 'react';
import '../styles/About.css';
import '../styles/App.css';

class About extends Component {
  render() {
    return (
      <div className="container">
        <h1>About Me</h1>

        <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cumque expedita et, at incidunt a fugit, voluptas culpa vero cupiditate ducimus maxime asperiores officia enim odio dolor dolore omnis, laboriosam suscipit?</p>

      </div>
    )
  }
}

export default About;
